import React from "react"
import { Link } from "gatsby"
import appImage from "../../../static/img/DownloadApp.png"

function AppDownloadBanner() {
  return (
    <div className="container section-gap">
      <div className="row">
        <div className="col-12 custom-shadow bg-white custom-rounded-lg">
          <div className="row p-4 align-items-center">
            <div className="col-12 col-xl-6 px-0 mb-3">
              <img
                src={appImage}
                alt="Download Estates Plus"
                className="w-100"
              />
            </div>

            <div className="col-12 col-xl-6 px-0">
              <h2 className="mb-4 font-black text-center text-xl-left">
                A Free App To Help With Deceased Estates
              </h2>
              <p className="mb-4 text-center text-xl-left">
                Download our free app to help you understand what to do after a
                loved one has died. A step-by-step guide, save estate assets,
                keep track of expenses and more. Written by expert estate
                lawyers.
              </p>
              <div className="d-flex justify-content-center justify-content-xl-start">
                <Link
                  to="/lost-a-loved-one/download-app"
                  className="btn btn-primary"
                >
                  Download
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AppDownloadBanner
