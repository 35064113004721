import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import { Accordion, Card } from "react-bootstrap"
import { FiChevronRight } from "react-icons/fi"

import "./styles.scss"
import MarkdownContent from "../../utils/ShowdownConverter"

export const FaqTemplate = ({ items, children }) => {
  return (
    <div className="row align-items-center justify-content-center">
      {children || ""}
      <div className="col-12 col-lg-10">
        <Accordion id="accordion">
          {items.map(({ question, answer }, i) => {
            const accordionKey = i + 1
            return (
              <Card key={`faq-${i}`} className="card mb-5">
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey={accordionKey}
                  className="card-header px-4 py-3"
                >
                  <div className="text-uppercase btn btn-block text-left font-black font-size-lg pl-0 d-flex align-items-center  justify-content-between">
                    {question}
                    <FiChevronRight className="card-handle" />
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={accordionKey}>
                  <Card.Body className="card-content">
                    <MarkdownContent className="mx-4 mb-4" content={answer} />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            )
          })}
        </Accordion>
      </div>
    </div>
  )
}

const Faq = ({ name, children }) => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { sectionKey: { eq: "sections/faq" } }) {
        frontmatter {
          questions {
            name
            items {
              question
              answer
            }
          }
        }
      }
    }
  `)

  const { questions } = data.markdownRemark.frontmatter
  const findSection = name => questions.find(question => question.name === name)
  const sectionName = !!findSection(name) ? name : "home"

  const selectedSection = findSection(sectionName)

  return (
    <div className="container section-gap">
      <FaqTemplate items={selectedSection.items}>{children}</FaqTemplate>
    </div>
  )
}

Faq.propTypes = {
  name: PropTypes.string,
}

Faq.defaultProps = {
  name: "home",
}

export default Faq
