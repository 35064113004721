import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"

import "./styles.scss"

export const StepsTemplate = ({ steps, colCount }) => {
  return (
    <div className="row">
      {steps.map((step, i) => {
        const baseOpacity = 0.05
        const cardOpacity = (i + 1) * baseOpacity
        const column = 12 / colCount

        return (
          <div
            className={`col-12 px-4 px-sm-7 px-md-10 px-lg-2 col-lg-${column} step`}
            key={i}
          >
            <div
              className="d-flex flex-column align-items-center align-items-md-start py-5 px-4 step-inner"
              style={{
                backgroundColor: `rgba(0, 145, 197, ${cardOpacity})`,
              }}
            >
              <img
                src={step.icon.publicURL}
                alt={step.title}
                className="mb-2 step-icon"
              />
              <span className="text-secondary mb-2 font-bold heading">
                Step {i + 1}
              </span>
              <span className="font-bold mb-3 d-inline-block sub-heading text-center text-md-left">
                {step.title}
              </span>
              <p className="details font-body text-center text-md-left mb-0">
                {step.details}
              </p>
            </div>
          </div>
        )
      })}
    </div>
  )
}

const Steps = ({ stepsName, colCount, children }) => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { sectionKey: { eq: "sections/steps" } }) {
        frontmatter {
          steps {
            name
            columns {
              icon {
                publicURL
              }
              title
              details
            }
          }
        }
      }
    }
  `)

  const { steps } = data.markdownRemark.frontmatter
  const selectedSteps = steps.find(step => step.name === stepsName)

  return (
    <div className="container section-gap">
      {children}
      <StepsTemplate steps={selectedSteps.columns} colCount={colCount} />
    </div>
  )
}

Steps.propTypes = {
  stepsName: PropTypes.string.isRequired,
  colCount: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default Steps
