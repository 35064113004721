import React from "react"
import Nick from "../../../static/img/image_54.png"
import Debbie from "../../../static/img/debbie.png"

import PreviewCompatibleImage from "../../components/PreviewCompatibleImage"
import { Button } from "react-bootstrap"
import { Link } from "gatsby"
import "./guide-style.scss"

export function Guide() {
  return (
    <section className="bg-light pb-3 p-lg-5">
      <section className="container bg-light guide-card">
        <div className="row center bg-white m-0 shadow-sm overflow-hidden">
          <div className="col-lg-7 pt-3 pt-xl-5 pl-xl-5 pb-3 pd-lg-0 order-1 order-lg-0">
            <h2 className="guide-title">
              Free Guide On What To Do
              <div>After A Loved One Dies</div>
            </h2>
            <h5 className="my-4 guide-desc">
              Understand what steps you need to take when a loved one dies.
              Written by our expert legal team.
            </h5>
            <Link to="/guides/deceased-estate">
              <Button size="sm" className="bg-orange-a100">
                Read More
              </Button>
            </Link>
          </div>
          <div className="col-lg-5 p-0">
            <PreviewCompatibleImage src={Nick} alt="img" />
          </div>
        </div>
      </section>
    </section>
  )
}

export function DebbieStory() {
  return (
    <section className="bg-light pb-3 p-lg-5">
      <section className="container bg-light guide-card">
        <div className="row center bg-white m-0 shadow-sm">
          <div className="col-lg-7 pt-3 pt-xl-5 pl-xl-5 pb-3 pd-lg-0 order-1 order-lg-0">
            <h2 className="guide-title">
              Read Debbie’s Brave Story
              <div>About Losing Her Son</div>
            </h2>
            <br />
            <p>“The whole thing was totally unexpected.”</p>
            <br />
            <p>
              Read Debbie’s brave story. It’s important to understand that you
              are not alone.
            </p>
            <Link to="/blogs/debbies-story/">
              <Button size="sm">Read More</Button>
            </Link>
          </div>
          <div className="col-lg-5 p-0">
            <PreviewCompatibleImage src={Debbie} alt="img" />
          </div>
        </div>
      </section>
    </section>
  )
}
