import React from "react"
import PropTypes from "prop-types"

import PreviewCompatibleImage from "../PreviewCompatibleImage"
import MarkdownToHtml from "../MarkdownToHtml"

import "./styles.scss"

const InfoSection = ({ infoList, noGap, children }) => {
  return (
    <div className={`container ${!noGap ? 'section-gap' : ''}`}>
      {children}
      {infoList.map(({ hasImage, image, isInverted, title, details, alt = "" }, i) => {
        let imageOrder = ""
        let textOrder = ""
        let cardMargin = "mb-3 mb-sm-7"
        const imgAlt = !!alt ? alt : title

        // alternate cards logic
        if (i % 2 === 0 && hasImage && !isInverted) {
          imageOrder = "order-xl-2"
          textOrder = "order-xl-1"
        }

        // if last item, remove margin
        if (i === infoList.length - 1) {
          cardMargin = ""
        }

        return (
          <div
            key={i}
            className={`info-card row p-4 p-sm-0 p-lg-0 custom-shadow overflow-hidden ${cardMargin}`}
          >
            {hasImage ? (
              <div
                className={`info-image col-12 col-xl-6 alternateCards--image px-0 ${imageOrder}`}
              >
                <PreviewCompatibleImage
                  className="h-100"
                  src={image}
                  alt={imgAlt}
                />
              </div>
            ) : (
              ""
            )}
            <div
              className={`info-text col-12 px-0 px-sm-4 pt-5 py-sm-4 p-lg-5 ${textOrder} ${
                hasImage ? "col-xl-6" : ""
              }`}
            >
              <div className="info-text-inner d-flex flex-column justify-content-center">
                <h2 className="info-title mb-4 font-bold font-size-lg-5">
                  {title}
                </h2>
                <MarkdownToHtml className="info-details" content={details} />
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

InfoSection.propTypes = {
  infoList: PropTypes.array.isRequired,
  noGap: PropTypes.bool
}

InfoSection.defaultProps = {
  noGap: false
}

export default InfoSection
