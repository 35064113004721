import React from "react"
import PropTypes from "prop-types"
import remark from "remark"
import remarkHtml from "remark-html"
import recommended from "remark-preset-lint-recommended"

const mdToHtml = content => {
  return remark()
    .use(recommended)
    .use(remarkHtml)
    .processSync(content)
    .toString()
}

const MarkdownToHtml = ({ content, className, style }) => {
  return (
    <div
      className={className}
      style={style}
      dangerouslySetInnerHTML={{ __html: mdToHtml(content) }}
    ></div>
  )
}

MarkdownToHtml.propTypes = {
  content: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
}

MarkdownToHtml.defaultProps = {
  className: "",
  style: {},
}

export default MarkdownToHtml
